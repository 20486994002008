<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 员工
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input v-model="query.name" placeholder="姓名" class="handle-input mr10"></el-input>
        <el-input v-model="query.phone" placeholder="手机号" class="handle-input mr10"></el-input>
        <el-select v-model="query.isvalidate" clearable placeholder="允许登录" class="handle-select mr10">
          <el-option key="1" label="是" value="1"></el-option>
          <el-option key="2" label="否" value="0"></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">添加</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="手机号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="c_at" :formatter="dateFormat" label="添加时间"></el-table-column>
        <el-table-column label="操作" width="280" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-edit"
                style="color:#e6a23c"
                @click="authEdit(scope.$index, scope.row)"
            >权限
            </el-button>
            <el-button
                type="text"
                icon="el-icon-edit"
                style="color:#e6a23c"
                @click="editPassword(scope.row)"
            >修改密码
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.row.id)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.page"
            :page-size="query.page_size"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="25%">
      <el-form ref="form" :model="form" label-width="80px" >
        <el-form-item label="姓名" required>
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" required>
          <el-input v-model="form.phone" ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
      </template>
    </el-dialog>

    <!-- 权限弹出框 -->
    <el-dialog title="设置权限" v-model="authVisible" width="25%">
      <el-checkbox-group v-model="checkAuthList">
        <el-checkbox v-for="(item, index) in authList" :key="index" :label="item.id">{{item.name}}</el-checkbox>
      </el-checkbox-group>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="authVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveAuth">确 定</el-button>
                </span>
      </template>
    </el-dialog>

    <!-- 密码弹出框 -->
    <el-dialog title="修改密码" v-model="editPassVisible" width="25%">
      <el-form ref="form" label-width="70px" >
        <el-form-item label="密码">
          <el-input v-model="password" show-password></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editPassVisible = false">取 消</el-button>
                    <el-button type="primary" @click="savePassword">确 定</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {getAdminList, saveAdmin, deleteAdmin, setAdminPass} from "../api/admin";
import {getMemu, getMenuList, setAdminMenu} from '../api/login'
import moment from "moment";

export default {
  name: "companytable",
  components: {},
  data() {
    return {
      editorOption: {
        placeholder: '编辑内容'
      },
      query: {
        name: "",
        phone: "",
        isvalidate: null,
        page: 1,
        page_size: 10
      },
      tableData: [],
      authList: [],
      checkAuthList: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      authVisible: false,
      editPassVisible: false,
      pageTotal: 0,
      password: "",
      form: {
      },
      idx: -1,
      id: -1
    };
  },
  created() {
    this.getData();
  },
  methods: {
    release(content){
      console.log(content);
    },
    tinymcechange(content) {
      this.form.content = content
      // this.$emit("tinymcechange", e);
    },
    dateFormat: function (row, column) {
      const date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(parseInt(date + "000")).format("YYYY-MM-DD")
    },
    // 获取 easy-mock 的模拟数据
    getData() {
      getAdminList(this.query).then(res => {
        this.tableData = res.data
        if (this.query.page === 1) {
          this.pageTotal = res.count
        }
      })
    },
    // 触发搜索按钮
    handleSearch() {
      getAdminList(this.query).then(res => {
        this.tableData = res.data
        if (this.query.page === 1) {
          this.pageTotal = res.count
        }
      })
    },
    // 删除操作
    handleDelete(id) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      }).then(() => {
        deleteAdmin(id).then(res => {
          if (res === true) {
            this.$message.success('删除成功')
          }
          this.getData()
        })
      }).catch(() => {
      });
    },
    // 添加操作
    handleAdd() {
      this.form = {}
      this.form.id = 0
      this.editVisible = true
    },
    editPassword(row) {
      this.form = row
      this.editPassVisible = true
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      saveAdmin(this.form, this.form.id).then(res => {
        if (res === true) {
          this.$message.success('保存成功')
          this.editVisible = false;
        }
        this.getData()
      })
      this.editVisible = false;
    },
    // 设置权限
    authEdit(index, row) {
      this.checkAuthList = []
      this.idx = index
      this.form = row
      let that = this
      getMenuList().then(res => {
        this.authList = res
      })
      getMemu(row.id).then(res => {
        res.forEach(function (value){
          that.checkAuthList.push(value.id)
        })
        this.authVisible = true
      })
    },
    saveAuth() {
      let auths = ''
      this.checkAuthList.forEach(function (value) {
        auths += value+','
      })

      setAdminMenu(this.form.id, {ids:auths.trim(',')}).then(res => {
        if (res == true) {
          this.$message.success("保存成功")
        }
      })
    },
    savePassword() {
      setAdminPass(this.password, this.form.id).then(res => {
        if (res === true) {
          this.$message.success('保存成功')
          this.editVisible = false;
        }
        this.getData()
      })
      this.editVisible = false;
    },
    // 分页导航
    handlePageChange(val) {
      this.query.page = val
      getAdminList(this.query).then(res => {
        this.tableData = res.data
      })
    },
    // eslint-disable-next-line no-unused-vars
    handleAvatarSuccess(res, file) {
      this.form.logo = res.url;
    },
    // eslint-disable-next-line no-unused-vars
    handleCoverSuccess(res, file) {
      this.form.cover = res.url
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
