import request from '../utils/request';
import qs from "qs";

export function getAdminList(param) {
    return request({
        url: '/admin-list',
        method: 'GET',
        params: param
    })
}

export function saveAdmin(data, id) {
    return request({
        url: '/admin/' + id,
        method: 'POST',
        data
    })
}

export function deleteAdmin(id) {
    return request({
        url: '/admin/' + id,
        method: 'DELETE'
    })
}

export function setAdminPass(pass, id) {
    return request({
        url: '/admin/' + id + '/pass',
        method: 'PUT',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({"password":pass})
    })
}
