<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 物业
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input v-model="query.deptname" placeholder="部门" class="handle-input mr10"></el-input>
        <el-select v-model="query.isvalidate" clearable placeholder="有效性" class="handle-select mr10">
          <el-option key="1" label="有效" value="1"></el-option>
          <el-option key="2" label="无效" value="0"></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">添加</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
        <el-table-column prop="deptname" label="部门"></el-table-column>
        <el-table-column prop="username" label="联系人" width="80"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" width="130"></el-table-column>
        <el-table-column prop="phone" label="手机号" width="130"></el-table-column>
        <el-table-column prop="website" label="网址" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="服务名称" width="130"></el-table-column>
        <el-table-column prop="sort" label="排序" width="100px;" align="center">
          <template #default="scope">
            <el-input-number style="width: 70px;" v-model="scope.row.sort" :controls="false" @change="setSort(scope.row.id, scope.row.sort)"
                             :precision="0" :step="1" :min="0" :max="10">
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="是否有效" width="100" align="center">
          <template #default="scope">
            <el-button type="text" :style="'color:'+[!scope.row.isvalidate?'red':'']" @click="setIsvalidate(scope.$index, scope.row.id, !scope.row.isvalidate)">
              {{ scope.row.isvalidate ? "有效" : "无效" }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="c_at" :formatter="dateFormat" label="添加时间" width="200px;"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.row.id)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.page"
            :page-size="query.page_size"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="80%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="部门名称" required>
          <el-input v-model="form.deptname"></el-input>
        </el-form-item>
        <el-form-item label="联系人" required>
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" required>
          <el-input v-model="form.mobile" :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model.trim="form.phone" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="网址">
          <el-input v-model="form.website"></el-input>
        </el-form-item>
        <el-form-item label="服务名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMerchantsList,
  saveMerchants,
  deleteMerchants,
  setMerchantsSort,
  setMerchantsIsvalidate
} from "../api/merchants";
import moment from "moment";

export default {
  name: "realtytable",
  components: {},
  data() {
    return {
      editorOption: {
        placeholder: '编辑内容'
      },
      query: {
        deptname: "",
        isvalidate: null,
        isrealty: 1,
        page: 1,
        page_size: 10
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {
        type: 3
      },
      idx: -1,
      id: -1
    };
  },
  created() {
    this.getData();
  },
  methods: {
    dateFormat: function (row, column) {
      const date = row[column.property];
      if (date === undefined) {
        return "";
      }
      return moment(parseInt(date + "000")).format("YYYY-MM-DD")
    },
    // 获取 easy-mock 的模拟数据
    getData() {
      getMerchantsList(this.query).then(res => {
        this.tableData = res.data
        if (this.query.page === 1) {
          this.pageTotal = res.count
        }
      })
    },
    // 触发搜索按钮
    handleSearch() {
      getMerchantsList(this.query).then(res => {
        this.tableData = res.data
        if (this.query.page === 1) {
          this.pageTotal = res.count
        }
      })
    },
    // 删除操作
    handleDelete(id) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      }).then(() => {
        deleteMerchants(id).then(res => {
          if (res === true) {
            this.$message.success('删除成功')
          }
          this.getData()
        })
      }).catch(() => {
      });
    },
    // 添加操作
    handleAdd() {
      this.form = {}
      this.form.id = 0
      this.editVisible = true
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      this.form.isrealty = true
      saveMerchants(this.form, this.form.id).then(res => {
        if (res === true) {
          this.$message.success('保存成功')
          this.editVisible = false;
        }
        this.getData()
      })
    },
    // 设置排序
    setSort(id, sort) {
      setMerchantsSort(id, sort).then(res => {
        if (res == true) {
          this.$message.success('保存成功')
        }
      })
    },
    setIsvalidate(index, id, isvalidate) {
      setMerchantsIsvalidate(id, isvalidate).then(res => {
        if (res === true) {
          this.$message.success('保存成功')
          this.tableData[index].isvalidate = isvalidate
        }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.query.page = val
      getMerchantsList(this.query).then(res => {
        this.tableData = res.data
      })
    },
    // eslint-disable-next-line no-unused-vars
    handleAvatarSuccess(res, file) {
      this.form.cover = res.url;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
