import request from '../utils/request';

export function getCompanyList(param) {
    return request({
        url: '/company-list',
        method: 'GET',
        params: param
    })
}

export function saveCompany(data, id) {
    return request({
        url: '/company/' + id,
        method: 'POST',
        data
    })
}

export function deleteCompany(id) {
    return request({
        url: '/company/' + id,
        method: 'DELETE'
    })
}

export function setCompanySort(id, sort) {
    return request({
        url: '/company/'+id+'/sort/'+sort,
        method: 'PUT'
    })
}

export function setCompanyIsg(id, isg) {
    return request({
        url: '/company/'+id+'/isg/'+isg,
        method: 'PUT'
    })
}

export function setCompanyIsvalidate(id, isvalidate) {
    return request({
        url: '/company/'+id+'/isvalidate/'+isvalidate,
        method: 'PUT'
    })
}
