import request from '../utils/request';
import qs from "qs";

export function login(data) {
    return request({
        url: '/admin/login',
        method: 'POST',
        data
    })
}

export function getMemu(id) {
    return request({
        url: '/admin/' + id + '/menu',
        method: 'GET'
    })
}

export function setAdminMenu(id, data) {
    return request({
        url: '/admin/' + id + '/menu',
        method: 'PUT',
        header: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: qs.stringify(data)
    })
}

export function getMenuList() {
    return request({
        url: '/menu-list',
        method: 'GET'
    })
}
