import request from '../utils/request';

export function getDictList() {
    return request({
        url: '/dict-list',
        method: 'GET'
    })
}

export function saveDict(id, data) {
    return request({
        url: '/dict/' + id,
        method: 'PUT',
        data
    })
}
