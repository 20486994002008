import request from '../utils/request';

export function getOpinionList(param) {
    return request({
        url: '/opinion-list',
        method: 'GET',
        params: param
    })
}

export function getOpinion(id) {
    return request({
        url: '/opinion/'+id,
        method: 'GET',
    })
}

export function saveOpinion(data, id) {
    return request({
        url: '/opinion/'+id,
        method: 'POST',
        data
    })
}

export function deleteOpinion(id) {
    return request({
        url: '/opinion/'+id,
        method: 'delete'
    })
}
